<template>
  <v-container
    v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_REGISTRALES)"
    id="crud"
    fluid
    tag="section"
  >
    <!--<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
			</v-toolbar> </v-card
		>-->
    <v-row
      ><v-col cols="auto" md="8" class="">
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          sort-by="secret"
          class="elevation-4 data__table"
        >
          <template v-slot:[`item.priceList`]="{ item }">
            {{ $formatMoney(item.priceList.Price) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_REGISTRALES)"
              small
              :color="$cv('btnEditar')"
              title="Agregar"
              @click="AgregarProductos(item)"
            >
              mdi-plus-box
            </v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat color="white" class="pr-2">
              <v-row>
                <v-col sm="12" class="pt-10">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="'Busqueda de ' + title"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
        <AgregarProductos
          v-if="dialog"
          :item="editedItem"
          :action="accionProducto"
          @terminar="dialog = !dialog"
        />
      </v-col>
      <Carrito @editarCarrito="editProductos" />
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Carrito from "@/views/dashboard/components/secundarios/Carrito.vue";
import AgregarProductos from "@/views/dashboard/components/secundarios/AgregarProductos.vue";

function title() {
  return "Elementos Registrales";
}

export default {
  components: {
    Carrito,
    AgregarProductos,
  },

  data: (vm) => ({
    accionProducto: "",
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,
    editedItem: {
      nombre: "",
      id: "",
    },
    defaultItem: {
      nombre: "",
      id: "",
    },
    keyTableAutorizados: 0,
    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      { text: "Código", filterable: true, value: "ItemCode" },
      { text: "Producto", filterable: true, value: "ItemName" },
      { text: "P. Unitario", filterable: true, value: "priceList" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    route: "elementosRegistrales",

    desserts: [],
  }),

  created() {
    this.getElementos();
  },

  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
    this.$store.commit("SET_CART_EDIT_PATH", "/elementos_registrales");
    //this.fetchProductos();
    this.resetSourceVentaElementosAduana();
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
  },

  methods: {
    getElementos() {
      console.log(this.$store.state.user.sapProperties);
      let userProps = this.$store.state.user.sapProperties;
      let formSap = {};
      const trueProps = Object.entries(userProps)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      //console.log(trueProps);
      trueProps.push(this.$store.state.user.sapProperties.PriceListNum);
      formSap.userProps = trueProps;
      Swal.alertGetInfo("Buscando información");
      this.$axiosApi
        .post(this.route, formSap)
        .then((r) => {
          if (r.data.data) {
            this.desserts = r.data.data;
          }

          Swal.close();
        })
        .catch(function (error) {
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";

          Swal.close();
        });
    },

    ...mapMutations("carrito", ["editProducto"]),
    ...mapMutations("aduana", ["resetSourceVentaElementosAduana"]),

    AgregarProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.subtotal = 0;
      this.accionProducto = "AGREGAR PRODUCTO";
      this.dialog = true;
    },
    editProductos(item) {
      this.editedItem = Object.assign({}, item);
      this.accionProducto = "EDITAR PRODUCTO";
      this.dialog = true;
    },
  },
};
</script>

<style>
.data__table {
  overflow-y: auto;
  max-height: 50.75rem;
}
</style>
